import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { t } from '../lib/translate';
import Neono from './Neono';
import Page from '../Page';
import { useLang } from "../contexts/LangContext";
import TopBar from "./TopBar";


async function fetchSrc() {
  const src = require.context('../assets/md/ru', false, /\.md$/);
  const fetchPromises = src.keys().map(filename =>
    fetch(src(filename)).then((response) => response.text())
  );

  return Promise.all(fetchPromises).then(async (contents) => {
    let md = [];
    await contents.map(async (content, index) => {
      md.push({
        filename: src.keys()[index],
        content,
      })
    });

    return md;
  });
}

const fetchAll = async () => {
  const a = [];
  a.push(await fetchSrc());
  return a;
}


export default function Book() {


  const [n, setN] = useState(0);
  const [all, setAll] = useState(false);
  const { lang } = useLang();
  const [mdf, setMdf] = useState([]);
  const { toc, setToc } = useLang();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        setLoading(true);
        const a = await fetchAll();
        setAll(a);

        const tc = [];
        a[n].forEach((file) => {
          tc.push({
            link: `#${file.filename.replace(/\.\//gm, '')}`,
            title: file.content.split('\n')[0].replace(/#/gm, '')
          });
        });
        setMdf(a[n]);
        setToc(tc);
        setLoading(false);
    })();

  }, [n]);

  return (
    <Page>

      <TopBar setN={setN}/>

      {loading && <Neono />}

      <div className="space">

        <div className="toc">
          <a name="top" id="top">&nbsp;</a>
          <h3 className="toc__title">{t[lang].contents}</h3>
          <ul>
            {toc?.map((item, index) => (
              <li key={index}>
                <a href={item.link}>{item.title}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="book__content">
          {mdf?.map((file, index) => (
            <div key={index}>
              <a
                className="chunk-anchor"
                onClick={
                  () => {
                    const element = document.getElementById('top');
                    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                  }}
                href="#top">↑</a>              
              <a
                className="chunk-anchor"
                onClick={
                  () => {
                    const element = document.getElementById(file.filename.replace(/\.\//gm, ''));
                    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                  }}
                id={file.filename.replace(/\.\//gm, '')}
                name={file.filename.replace(/\.\//gm, '')}
                href={`#${file.filename.replace(/\.\//gm, '')}`}>&nbsp;</a>
              <ReactMarkdown>{file.content}</ReactMarkdown>
            </div>
          ))}
        </div>

      </div>
    </Page>

  );

}
