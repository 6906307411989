import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Book from "./components/Book";
import Chunks from "./components/Chunks";
import Front from "./components/Front";
import NotFound from "./components/NotFound";

export default function App() {  
 
  return (
    <>
      <div className={`app`}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Front />} />
          <Route path="/book" element={<Book />} />
          <Route path="/book-ru" element={<NotFound />} />
          <Route path="/book-cz" element={<NotFound />} />
          <Route path="/book-en" element={<NotFound />} />
          <Route path="/chunks" element={<Chunks />} />
          <Route path="" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </>
  );
}


