import React, {
  createContext, useContext, useState, 
} from 'react';
import store from 'store2';

const LangContext = createContext({
  lang: '',
  setLang: () => {},
  mdf: [],
  setMdf: () => {},
  toc: [],
  setToc: () => {},
});

function LangProvider({ children }) {
  const [lang, setLang] = useState('RU');
  const [mdf, setMdf] = useState([]);
  const [toc, setToc] = useState([]);

  const reset = () => {
    setMdf([]);
    setToc([]);
  }


  const values = {
    lang, setLang, mdf, setMdf, toc, setToc, reset,
  };

  return (
    <LangContext.Provider value={values}>
      {children}
    </LangContext.Provider>
  );
}

export { LangProvider, LangContext };

export const useLang = () => useContext(LangContext);
