import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { splitText } from "../lib/translate";
import Page from '../Page';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


async function fetchSrc() {
  const src = require.context('../assets/md/ru', false, /\.md$/);
  const fetchPromises = src.keys().map(filename =>
    fetch(src(filename)).then((response) => response.text())
  );

  return Promise.all(fetchPromises).then(async (contents) => {
    let md = [];
    await contents.map(async (content, index) => {
      md.push({
        filename: src.keys()[index],
        content,
      })
    });

    return md;
  });
}

export default function Chumks() {

  const chunkSize = 3500;
  const chunkOverlap = 20;
  const separator = "\n";
  const avgWordLength = 6.17;
  const pageLength  = 1860;

  const [text, setText] = useState("");
  const [chunks, setChunks] = useState([]);
  const [length, setLength] = useState(0);

  useEffect(() => {
    ( async () => {
      if (!text.length) {
        let txt = '';
        const md =  await fetchSrc();
        md.forEach( fragment => txt += fragment.content + '\n\n');
        setText(txt);
        setChunks(splitText(txt, chunkSize, chunkOverlap, separator));
        setLength(txt.length);
      }
    })()
  }, []);

  const downloadChunksAsZip = () => {
    const zip = new JSZip();
    chunks.forEach((chunk, index) => {
      const filename = `chunk_${index + 1}.md`;
      zip.file(filename, chunk);
    });
    zip.generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "chunks.zip");
      });
  };

  return (
    <Page>
      <div className="space">
      {chunks?.map((c, i) => (
        <div key={i} className="chunk">
          <h1>Chunk {i + 1}</h1>
          <ReactMarkdown>{c}</ReactMarkdown>
        </div>
      ))}
      </div>
      <div className="space">
        <button onClick={downloadChunksAsZip}>Download Chunks as ZIP</button>
      </div>
      <div className="space">
        {length} characters | {parseInt(length / avgWordLength)} words | {parseInt(length / pageLength)} pages | {chunks.length} chunks
      </div>
    </Page>
  );
}
