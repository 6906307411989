export const translate = async (text, target_lang) => {
  try {
    const response = await fetch('https://dash.martlet.live/wp-json/dash/v1/deepl', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
      },
      body: JSON.stringify({
        text,
        target_lang,
      })
    });

    const data = await response.json();
    console.log(data);

    if (data.translations.length)
      return data.translations[0].text;

  } catch (error) {
    console.log('oops... ', error);
  }
}

export const t = {

  'RU': {
    title: 'RU',
    lang: 'Русский',
    home: 'Главная',
    poems: 'Стихи',
    seasons: 'Времена года',
    book: 'Книга',
    enter: `Войти`,
    logout: `Выйти`,
    refresh: `Обновить`,
    contents: `Содержание`,
  },
  'EN':
  {
    title: 'EN',
    lang: 'English',
    home: 'Home',
    poems: 'Poems',
    seasons: 'Seasons',
    book: 'Book',
    enter: `Enter`,
    logout: `Logout`,
    refresh: `Refresh`,
    contents: `Contents`,
  },
  'CS':
  {
    title: 'CS',
    lang: 'Čeština',
    home: 'Domů',
    poems: 'Básně',
    seasons: 'Sezóny',
    book: 'Kniha',
    enter: `Vstoupit`,
    logout: `Odhlásit`,
    refresh: `Obnovit`,
    contents: `Obsah`,
  },
};


export const beforeTrans = (t) => t.replace(/^-\s/gm, '* ').replace(/(?:--|–|—)/g, 'ddpphh');

export const afterTrans = (tt) => tt.replace(/ddpphh/g, '—');


export function splitText(text, chunkSize, chunkOverlap, separator) {
  const chunks = [];
  const separatorLength = separator.length;
  let start = 0;

  while (start < text.length) {
    let end = start + chunkSize;
    let nextSeparatorIndex = text.indexOf(separator, end);

    if (nextSeparatorIndex === -1) {
      chunks.push(text.substring(start));
      break;
    }

    end = nextSeparatorIndex + separatorLength;

    // Adding overlap if not at the end of the text
    if (end + chunkOverlap < text.length) {
      end += chunkOverlap;
    }

    chunks.push(text.substring(start, end));
    start = nextSeparatorIndex + separatorLength;
  }

  return chunks;
}
