import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { NotifyProvider } from "./contexts/NotifyContext";
import { LangProvider } from "./contexts/LangContext";
import App from './App';
import "./app.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LangProvider>
      <NotifyProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NotifyProvider>
    </LangProvider>
  </React.StrictMode>
);

