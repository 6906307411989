import React from "react";
import store from 'store2';
import { Navigate } from "react-router-dom";
import Page from '../Page';
import './Front.scss';


export default function Front() {

  const loggedIn = store.get('loggedIn') ?? false;

  return (
    loggedIn ? <Navigate to={'/book'} replace={true} /> : <Page />
  )
}