import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from 'store2';
import MessagePanel from './components/MessagePanel';
import { useLang } from './contexts/LangContext';
import { t } from './lib/translate';
import './Page.scss';


export default function Page({ children, className }) {


  const { lang } = useLang();
  const [pwd, setPwd] = useState('');
  const [loggedIn, setLoggedIn] = useState(store.get('loggedIn') ?? false);
  const navigate = useNavigate();
  const location = useLocation();

  const login = () => {
    if (pwd === 'effatha') {
      store.set('loggedIn', 1);
      setLoggedIn(true);
      setPwd('');
      location.pathname === '/' && navigate('/book');
    }
  }


  return (
    !loggedIn ? (

      <div className="page">
        <div className='Front'>
          <div className="login">
            <input className="login__input" type="text" onChange={(e) => setPwd(e.target.value)} onKeyUp={(e) => {
              e.key === 'Enter' && login();
              }
            } value={pwd} />
            <button className="login__btn" onClick={() => {
              login();
              }
            }>{t[lang].enter}</button>
          </div>
        </div>
      </div>
    ) : (
      <div className={`page ${className ?? ''}`}>
        <MessagePanel />
        <main className="main">
          {children}
        </main>
      </div>
    )
  )
}
