import React from 'react';
import './NotFound.scss'; 


export default function NotFound() {
  return (
    <>
    <div className={`not-found`}>
      <section>
      <h1>Lost</h1>
      <verse>
        Like bare gold<br />
        moon shines in darkening west <br />
        spread crystal wings <br />
        let fresh air in your chest <br />
        fern leaf you hold  <br />
        will lead you through the quest
      </verse>
      <a className="mtl-btn l" href="/">Home</a>
      </section>
    </div>
    </>
  );
}
