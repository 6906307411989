import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { t } from '../lib/translate';
import { useLang } from "../contexts/LangContext";


export default function TopBar( {} ) {

  const { lang, setLang } = useLang();
  const location = useLocation();


  useEffect(() => {
    location.pathname === '/book-cz' && setLang('CS');
  }, [location.pathname, setLang]);

  return (
    <div className="vol-menu">
      {location.pathname === '/book-ru' &&
        <>
          <Link to="/book-ru" className="vol-menu__link">
            {t[lang].book}
          </Link>
        </>
      }

    </div>
  );
}

